@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?tya7rj');
    src: url('fonts/icomoon.eot?tya7rj#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?tya7rj') format('truetype'),
    url('fonts/icomoon.woff?tya7rj') format('woff'),
    url('fonts/icomoon.svg?tya7rj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-Select_Building:before {
    content: "\e90a";
}

.icon-Select_empty:before {
    content: "\e90b";
}

.icon-Select_Person:before {
    content: "\e90c";
}

.icon-Select_X:before {
    content: "\e90d";
}

.icon-SpeechBubble:before {
    content: "\e90e";
    color: #fff;
}

.icon-Star_empty:before {
    content: "\e90f";
}

.icon-QuestionMark:before {
    content: "\e910";
}

.icon-Overflow_done:before {
    content: "\e911";
}

.icon-MyProfile:before {
    content: "\e912";
}

.icon-Home_done:before {
    content: "\e913";
}

.icon-Album:before {
    content: "\e900";
}

.icon-Star:before {
    content: "\e901";
}

.icon-Search:before {
    content: "\e902";
}

.icon-Ranking:before {
    content: "\e903";
}

.icon-info:before {
    content: "\e904";
}

.icon-Hat:before {
    content: "\e905";
}

.icon-RetroSnap:before {
    content: "\e906";
}

.icon-Marker:before {
    content: "\e907";
}

.icon-Gallery:before {
    content: "\e908";
}

.icon-RetroSnapPlus:before {
    content: "\e909";
}
