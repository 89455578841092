.custom-scrollbar{
    height: 100vh;
    overflow-y: auto;
}
.custom-scrollbar::-webkit-scrollbar {
    border-radius: 20px;
    width: 8px;
    height: 4px;
    background: #ECE5DE99
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #ded8fe;
}
